import articlesService from "../services/articles.service";
import TaxRatesDataService from "../services/tax-rates.service";

const modal = null;
const modalParams = null;
const alert = null;
const seller = {
  name: 'PRIMERO RENT A CAR D.O.O.',
  addr: 'Vase Pelagića 38 11040 Beograd, Srbija	',
  pib: 102449114,
  mb: 17453416,
  tr: '170-0030025128000-93, UniCredit Bank Srbija A.D.',
  trnum: '170-0030025128000-93',
  tr2: '325-9500700199947-08 OTP Banka Srbija a.d.',
  trnum2: '325-9500700199947-08',
  trCes: '145-0000000031478-19 Adriatic banka AD',
  trnumCes: '145-0000000031478-19',
  tel: '+ 381 11 785 2789; +381 11 785 2820',
  authorizedPerson: 'Stanko Janković',
  position: 'Generalni direktor'
};
const currentSelling = null;
const currentCession = null;

const initialState = { status: { showModal: false }, modal, modalParams, alert, seller, currentSelling, currentCession, taxRates: [], articles: {} }

export const app = {
  namespaced: true,
  state: initialState,
  actions: {
    async fetcTaxRates({ commit }) {
      const data = await TaxRatesDataService.getAll();
      commit('setTax', data.data.rows);
    },
    async fetcArticles({ commit }) {
      const proform = await articlesService.getDefault("Proform");
      commit('addArticle', { type: "Proform", data: proform.data });
      const advance = await articlesService.getDefault("Advance");
      commit('addArticle', { type: "Advance", data: advance.data });
      const normal = await articlesService.getDefault("Normal");
      commit('addArticle', { type: "Normal", data: normal.data });
    },
  },
  mutations: {
    showModal(state, data) {
      state.modal = data.modal;
      state.modalParams = data.params;
      state.status.showModal = true;
    },
    setModalData(state, data) {
      state.modalParams.data = data;
    },
    hideModal(state) {
      state.status.showModal = false;
      state.modal = null;
      state.modalParams = null;
      state.currentSelling = null;
      state.currentCession = null;
    },
    showAlert(state, alert) {
      state.alert = alert;
    },
    hideAlert(state) {
      state.alert = null;
    },
    setCurrentSelling(state, data) {
      state.currentSelling = data;
    },
    setCurrentCession(state, data) {
      state.currentCession = data;
    },
    setTax(state, tax) {
      state.taxRates = tax;
    },
    addArticle(state, data) {
      state.articles[data.type] = data.data;
    }
  }
};
